<template>
  <div class="main">
    <no-pass-com> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import noPassCom from "../components/noPassCom.vue";

export default {
  name: "noPass",
  components: {
    noPassCom,
  },
  data() {
    return {};
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
</style>
